import type { Discount, Item, Takeaway, Voucher } from '~/models';

import { useItem } from '~/composables/useItem';

const round = (value: number): number => {
  return Math.round((value + Number.EPSILON) * 100) / 100;
};

interface CartState {
  items: Item[];
  comment: string;
  voucher: Voucher | null;
}

const STORE_NAME = 'customer-cart';

const useCartStore = defineStore(STORE_NAME, () => {
  const defaultState = (): CartState => ({
    items: [],
    comment: '',
    voucher: null
  });

  const defaultTakeaway = (): Takeaway => ({
    type: 'Delivery',
    name: '',
    phone: '',
    address: '',
    city: '',
    postalCode: ''
  });

  const state = useCookie<CartState>(STORE_NAME, {
    path: '/',
    sameSite: 'strict',
    maxAge: 60 * 60 * 24 * 7,
    watch: true,
    decode: (value) => JSON.parse(decode(value)),
    encode: (value) => encode(JSON.stringify(value)),
    default: defaultState
  });

  const serviceCharge = ref<number>(0);
  const takeaway = ref<Takeaway>(defaultTakeaway());
  const voucherStore = useVoucherStore();

  const clear = (): void => {
    state.value = defaultState();
    takeaway.value = defaultTakeaway();
    serviceCharge.value = 0;
  };

  const addItem = (item: Item): void => {
    const itemInCart = state.value.items.find((inCart) =>
      useItem(inCart).toEqual(item)
    );

    if (itemInCart) {
      itemInCart.quantity += item.quantity;
      return;
    }

    state.value.items.push(item);

    if (state.value.voucher) {
      state.value.voucher.valid = voucherStore.isValid(
        state.value.voucher.discount
      );
    }
  };

  const removeItem = (item: Item): void => {
    if (item.quantity > 1) {
      item.quantity--;
      return;
    }

    const index = state.value.items.indexOf(item);
    state.value.items.splice(index, 1);

    if (state.value.voucher) {
      state.value.voucher.valid = voucherStore.isValid(
        state.value.voucher.discount
      );
    }
  };

  const setVoucher = (payload: Voucher | null): void => {
    state.value.voucher = payload;
  };

  const setComment = (payload: string): void => {
    state.value.comment = payload;
  };

  const setTakeaway = (payload: Takeaway | null): void => {
    takeaway.value = payload ?? defaultTakeaway();
  };

  const setTakeawayType = (takeawayType: Takeaway['type']): void => {
    takeaway.value.type = takeawayType;
  };

  const setServiceCharge = (fee: number): void => {
    serviceCharge.value = fee;
  };

  const deliveryFee = computed<number>(() => {
    return takeaway.value.type === 'Delivery' ? takeaway.value.fee ?? 0 : 0;
  });

  const voucherValue = computed<number>(() => {
    if (
      !state.value.voucher ||
      state.value.voucher.valid !== true ||
      !state.value.voucher.discount.value
    ) {
      return 0;
    }

    const total = round(
      subtotal.value + deliveryFee.value + serviceCharge.value
    );

    const getValue = (discount: Discount): number => {
      return discount.valueType === 'Percentage'
        ? round(total * (discount.value / 100))
        : discount.value;
    };

    return Math.min(getValue(state.value.voucher.discount), total);
  });

  const itemsDiscount = computed<number>(() => {
    return round(
      state.value.items.reduce((acc, curr) => {
        return acc + curr.quantity * curr.discount;
      }, 0)
    );
  });

  const discount = computed<number>(() => {
    return voucherValue.value + itemsDiscount.value;
  });

  const subtotal = computed<number>(() => {
    const subtotal = state.value.items.reduce((acc, curr) => {
      return acc + curr.quantity * (curr.price + curr.extra);
    }, 0);

    return round(subtotal);
  });

  const total = computed<number>(() => {
    return round(
      subtotal.value - discount.value + deliveryFee.value + serviceCharge.value
    );
  });

  return {
    items: computed<Item[]>(() => state.value.items),
    takeaway,
    serviceCharge,
    comment: computed<string>(() => state.value.comment),
    voucher: computed<Voucher | null>(() => state.value.voucher),
    clear,
    addItem,
    removeItem,
    setVoucher,
    setComment,
    setTakeaway,
    setTakeawayType,
    setServiceCharge,
    deliveryFee,
    voucherValue,
    itemsDiscount,
    discount,
    subtotal,
    total
  };
});

export { useCartStore };
