import type { Discount, TakeawayOption, Voucher } from '~/models';

import { useNuxtApp } from '#app';

const useVoucherStore = defineStore('customer-voucher', () => {
  const { $http } = useNuxtApp();

  const isValid = (discount: Discount): boolean | string => {
    const cartStore = useCartStore();
    const takeawayType = cartStore.takeaway.type;

    if (
      discount.takeawayOptions &&
      !discount.takeawayOptions.includes(
        takeawayType.toUpperCase() as TakeawayOption
      )
    ) {
      return `This voucher is not available for ${takeawayType}`;
    }

    const minValue = discount.minimunOrderValue || 0;

    if (minValue === 0) {
      return true;
    }

    const minimumValueIsValid = discount && minValue <= cartStore.subtotal;

    const errorMessage = `This voucher requires a minimum value of € ${discount.minimunOrderValue} to be accepted`;

    return minimumValueIsValid || errorMessage;
  };

  const get = async (code: string): Promise<Voucher> => {
    const discount = await $http.discount.voucher({ voucherCode: code });

    return {
      discount,
      valid: isValid(discount)
    };
  };

  return {
    get,
    isValid
  };
});

export { useVoucherStore };
